<template>
  <div class="page-footer">
    copyright &copy; agora.io 2023
  </div>
</template>

<script lang="js">
export default {
  name: 'PageFooter',
  setup() {
    return {

    }
  }
}
</script>

<style scoped lang="less">
@import '../../theme/common.less';
.page-footer {
  font-weight: @font-weight-thin;
}
</style>
<template>
  <div class="home">
    <el-row align="top">
      <el-col
        :xs="{span: 24, offset: 0}"
        :sm="{span: 6, offset: 0}"
        :md="{span: 6, offset: 0}"
        :lg="{span: 6, offset: 0}">
        <LeftMenu/>
      </el-col>
      <el-col
        :xs="{span:24,offset:0}"
        :sm="{span:18,offset:0}"
        :md="{span:18,offset:0}"
        :lg="{span:18,offset:0}">
        <router-view/>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="js">
import LeftMenu from './views/LeftMenu.vue'
export default {
  name: 'HomePage',
  components: {
    LeftMenu
  },
  setup() {
    return {
    }
  }
}
</script>

<style scoped lang="less">
.home {
  width: 100%;
  max-width: 1200px;
  margin-top: 60px;
  margin-bottom: 40px;
  margin: auto;
}
</style>

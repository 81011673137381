<template>
  <div id="app">
    <el-container class="layout">
      <el-header class="app-header">
        <PageHeader/>
      </el-header>
      <el-main :style="{margin: '0px 0px 0', minHeight: '500px'}" class="main-content">
        <router-view/>
      </el-main>
      <el-footer class="layout_footer">
        <PageFooter/>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import PageHeader from './components/views/PageHeader.vue'
import PageFooter from './components/views/PageFooter.vue';
import HomePage from './components/HomePage.vue'
export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

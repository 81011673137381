<template>
  <div>
    <el-menu class="" @select="handleSelect" :default-active="onRouters">
      <el-menu-item index="1">
        <el-icon><menu/></el-icon>
        <span>Collect Info</span>
      </el-menu-item>
      <el-sub-menu index="2">
        <template #title>
          <el-icon><location/></el-icon>
          <span>RTC Check</span>
        </template>
        <el-menu-item index="2-1">Best Practice Check</el-menu-item>
        <el-menu-item index="2-2">Additional Check</el-menu-item>
      </el-sub-menu>
      <el-menu-item index="3">
        <el-icon><menu/></el-icon>
        <span>Cloud Recording Check</span>
      </el-menu-item>
      <el-menu-item index="4">
        <el-icon><document /></el-icon>
        <span>Resource Check</span>
      </el-menu-item>
      <el-menu-item index="5">
        <el-icon><setting /></el-icon>
        <span>Account Check</span>
      </el-menu-item>
      <el-menu-item index="6">
        <el-icon><setting /></el-icon>
        <span>Complete</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script lang="js">
// import {router} from '@/router'
import {ref, computed, onMounted, watch, $refs} from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
  name: 'LeftMenu',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const currentId = ref('')
    const onRouters = ref('1')
    const menuItems = {
      '1': 'CollectInfo',
      '2': 'RTCCheck',
      '2-1': 'Best Practice Check',
      '2-2': 'Additional Check',
      '3': 'Cloud Recording Check',
      '4': 'Resource Check',
      '5': 'Account Check',
      '6': 'Complete',
    }
    const handleSelect = (key, keypath) => {
      //console.log('key:' + menuItems[key] + ' | keypath:' + keypath)
      switch (key) {
        case '1':
          router.push('CollectInfo')
          break
        case '2-1':
          router.push('Check?checkId=2-1')
          break
        case '2-2':
          router.push('Check?checkId=2-2')
          break
        case '3':
          router.push('Check?checkId=3')
          break
        case '4':
          router.push('Check?checkId=4')
          break
        case '5':
          router.push('Check?checkId=5')
          break
        case '6':
          router.push('Complete')
          break
      }
    }

    watch(() => route.query.checkId, (newValue, oldValue) => {
      currentId.value = newValue
      if ( newValue != oldValue ) {
        console.log('query changed to ' + route.query.checkId)
        onRouters.value = route.query.checkId
      }
    })
    return {
      onRouters,
      // handleClose,
      handleSelect
    }
  }
}
</script>

<style scoped lang="less">
</style>
// Router index
import { importManager } from 'less'
import { createRouter, createWebHistory, createWebHashHistory, createMemoryHistory } from 'vue-router'

const HomePage = () => import(/* webpackChunkName: "Home" */ '../components/HomePage.vue')
const CollectInfoPage = () => import(/* webpackChunkName: "Home" */ '../components/CollectInfoPage.vue')
const CheckPage = () => import(/* webpackChunkName: "Home" */ '../components/CheckPage.vue')
const CompletePage = () => import(/* webpackChunkName: "Home" */ '../components/CompletePage.vue')

const routerHistory = createWebHashHistory() //createWebHistory()
const router = createRouter({
  history: routerHistory,
  //mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
      meta: {
        title: 'darkzero'
      },
      children: [
        {path: '/', component: CollectInfoPage, meta: { title: 'Collect Info' }},
        {path: '/CollectInfo', component: CollectInfoPage, meta: { title: 'Collect Info' }},
        {path: '/Check', component: CheckPage, meta: { title: 'Check' }},
        {path: '/Complete', component: CompletePage, meta: { title: 'Complete' }},
      ]
    }
  ]
})
export default router